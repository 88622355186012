import jwt from 'jsonwebtoken';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _some from 'lodash/some';
import _invoke from 'lodash/invoke';
import _pickBy from 'lodash/pickBy';

import { roleBasedAccessControl } from '../constants/enums/roleBasedAuthorization';
import { RoleNames } from '../constants/enums/roleNames';
import { UserTypes } from '../constants/enums/userTypes';

export const roleBasedAuthorization = (feature: string): boolean => {
  const currentUserRoleGroup = getLocalStorageRoles();

  if (!feature) {
    return false;
  }

  return currentUserRoleGroup.some((role: string) => {
    if (
      (RoleNames.CUSTOMER.regex.test(role) && roleBasedAccessControl[feature].includes('CUSTOMER')) ||
      (RoleNames.CUSTOMER_ADMIN.regex.test(role) && roleBasedAccessControl[feature].includes('CUSTOMER_ADMIN'))
    ) {
      return true;
    }

    return roleBasedAccessControl[feature].includes(role);
  });
};

const getLocalStorageRoles = (): Array<string> => {
  const commaDelimitedRoles = localStorage.getItem('user_roles');
  if (!commaDelimitedRoles) {
    return [];
  }
  return commaDelimitedRoles.split(',');
};

// accepts RegExps, Strings, and RoleName enums to cross-check for matches against the user's roles in LocalStorage
const hasRole = (role: any): boolean => {
  if (!role) {
    return false;
  }
  const localStorageRoles = getLocalStorageRoles();
  if (_isEmpty(localStorageRoles)) {
    return false;
  }

  if (role instanceof RegExp) {
    return _some(localStorageRoles, lsr => role.test(lsr));
  }
  // role is RoleName enum
  if (role instanceof Object) {
    return _some(localStorageRoles, lsr => _invoke(role, 'regex.test', lsr));
  }
  if (role instanceof String) {
    // test for exact matches
    if (_some(localStorageRoles, lsr => lsr.toUpperCase() === role.toUpperCase())) {
      return true;
    }
    // test if role matches any registered RoleName regexes
    const roleEnum = _pickBy(RoleNames, rn => _invoke(rn, 'regex.test', role));
    return _invoke(roleEnum, 'regex.test', role);
  }
  return false;
};

export const getCustomerIdsOrNullIfAdmin = (): (number | null)[] | null => {
  const currentUserRoleGroup = getLocalStorageRoles();
  if (currentUserRoleGroup.includes('ADMINS')) {
    return null;
  }

  const pattern = /^CUSTOMER_(ADMIN_)?(\d+)$/;

  const ids = currentUserRoleGroup
    .map(role => {
      const match = role.match(pattern);
      return match ? parseInt(match[2], 10) : null;
    })
    .filter(it => it);
  return ids.length > 0 ? ids : null;
};

export const isUserTechnician = (): boolean => hasRole(RoleNames.TECHNICIAN);

export const isUserAdmin = (): boolean => hasRole(RoleNames.ADMINS);

export const isViewOnlyAdmin = (): boolean => {
  return hasRole(RoleNames.VIEW_ONLY_ADMIN);
};

export const isUserCustomer = (): boolean => hasRole(RoleNames.CUSTOMER);

export const isUserGoInstallerAllCustomer = (): boolean => hasRole(RoleNames.GO_INSTALLER_ALL_CUSTOMERS);

export const isUserGoInstallerCustomer = (): boolean => hasRole(RoleNames.GO_INSTALLER_CUSTOMER);

export const isCustomerInstaller = (): boolean => hasRole(RoleNames.CUSTOMER_INSTALLER);

export const isUserInstaller = (): boolean => hasRole(RoleNames.INSTALLER);

export const isUserInstallerOnly = (): boolean =>
  hasRole(RoleNames.INSTALLER) && ![RoleNames.CUSTOMER, RoleNames.CUSTOMER_ADMIN, RoleNames.ALL_CUSTOMER_VIEW, RoleNames.ADMINS].some(hasRole);

export const isAllCustomerView = (): boolean => hasRole(RoleNames.ALL_CUSTOMER_VIEW);

export const isAdminOrCustomer = (): boolean => isUserAdmin() || isUserCustomer();

export const isAdminOrCustomerOrAllCustomerView = (): boolean => isAdminOrCustomer() || isAllCustomerView();

export const isCustomerAdmin = (): boolean => hasRole(RoleNames.CUSTOMER_ADMIN);

export const isAdminOrCustomerAdmin = (): boolean => isUserAdmin() || isCustomerAdmin() || isViewOnlyAdmin();

export const isCustomerAdminOrCustomer = (): boolean => isUserCustomer() || isCustomerAdmin();

export const isUserSupport = (): boolean => hasRole(RoleNames.SUPPORT);

export const isUserDealer = (): boolean => hasRole(RoleNames.DEALER);

export const isUserDeveloper = (): boolean => hasRole(RoleNames.DEVELOPER);

export const determineUserRole = (): string[] | boolean => {
  const token = getLocalStorageToken();
  if (!token) {
    return false;
  }

  const tokenData = jwt.decode(token);
  const currentUserRoleGroup = _get(tokenData, 'cognito:groups', []);
  return currentUserRoleGroup;
};

const getLocalStorageToken = (): string => {
  const localStorageToken = localStorage.getItem('id_token');
  if (!localStorageToken) {
    return '';
  }
  return localStorageToken;
};

export const getUserTypeRole = (): string => {
  if (
    hasRole(RoleNames.ADMINS) ||
    hasRole(RoleNames.CUSTOMER_ADMIN) ||
    hasRole(RoleNames.SUPPORT) ||
    hasRole(RoleNames.VIEW_ONLY_ADMIN) ||
    hasRole(RoleNames.ALL_CUSTOMER_VIEW)
  ) {
    return UserTypes.ADMINISTRATOR.userType;
  } else if (hasRole(RoleNames.CUSTOMER) || hasRole(RoleNames.OEM)) {
    return UserTypes.STANDARD_USER.userType;
  } else if (
    hasRole(RoleNames.INSTALLER) ||
    hasRole(RoleNames.CUSTOMER_INSTALLER) ||
    hasRole(RoleNames.GO_INSTALLER_ALL_CUSTOMERS) ||
    hasRole(RoleNames.GO_INSTALLER_CUSTOMER) ||
    hasRole(RoleNames.TECHNICIAN) ||
    hasRole(RoleNames.OEM_INSTALLER)
  ) {
    return UserTypes.INSTALLER.userType;
  } else if (hasRole(RoleNames.DEALER)) return UserTypes.DEALER.userType;
  return 'N/A';
};
