import moment from 'moment-timezone';
import commaNumber from 'comma-number';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _invoke from 'lodash/invoke';
import { dateFormat, lastReportedTimestampFormat } from '../constants/index';
import { titleCase } from './textUtils';

const browsersTimezone = moment.tz.guess();

export function agGridDateComparator(date1, date2) {
  let date1Number = date1 && new Date(date1).getTime();
  let date2Number = date2 && new Date(date2).getTime();

  if (date1Number == null && date2Number == null) {
    return 0;
  }

  if (date1Number == null) {
    return -1;
  } else if (date2Number == null) {
    return 1;
  }

  return date1Number - date2Number;
}

export function agGridDateFilter(filterLocalDateAtMidnight, cellValue) {
  const formattedCellValue = moment(cellValue).format(dateFormat);
  if (formattedCellValue == null) {
    return 0;
  }
  const dateParts = formattedCellValue.split('/');
  const year = Number(dateParts[2]);
  const day = Number(dateParts[1]);
  const month = Number(dateParts[0]) - 1;
  const cellDate = new Date(year, month, day);
  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  } else {
    return 0;
  }
}

export const getUtcTimestampFormattedAsUserTimezoneAgGrid = ({ value }) => {
  if (!value) return '';

  try {
    return moment.utc(value).tz(browsersTimezone).format(lastReportedTimestampFormat);
  } catch (error) {
    console.error('Error parsing the date value:', error);
    return '';
  }
};

export const getFormattedTimestampAgGrid = ({ value }) => (value ? moment(value).tz(browsersTimezone).format(lastReportedTimestampFormat) : '');

export const getCargoWeightOnBogieTextAgGrid = value => {
  if (!value) {
    return 'N/A';
  }

  const formattedValue = Math.trunc(value);

  return `${commaNumber(formattedValue)}lbs`;
};

export const roundMileageAgGrid = value => {
  if (value === null) return 'N/A';
  return Number(value).toFixed(1);
};

export const sortNilsAlwaysLastAgGrid = (a, b, nodeA, nodeB, isInverted) => {
  if (a === b) {
    return 0;
  } else if (a === null) {
    return isInverted ? -1 : 1;
  } else if (b === null) {
    return isInverted ? 1 : -1;
  } else if (isInverted) {
    return a > b ? 1 : -1;
  } else if (!isInverted) {
    return b > a ? -1 : 1;
  }
};

export const sortNilsAlwaysLastGroupsAgGrid = (a, b, nodeA, nodeB, isInverted) => {
  const aName = _map(a, 'name').toString();
  const bName = _map(b, 'name').toString();
  if (aName === bName) {
    return 0;
  } else if (aName === 'All VINs') {
    return isInverted ? -1 : 1;
  } else if (bName === 'All VINs') {
    return isInverted ? 1 : -1;
  } else if (isInverted) {
    return aName > bName ? 1 : -1;
  } else if (!isInverted) {
    return bName > aName ? -1 : 1;
  }
};

export const getFormattedUntetheredTimeElapsedAgGrid = ({ value }) => {
  if (!value) return '-';

  return `${Math.trunc(value)} days untethered`;
};

export const occupiedCustomerSpecificGeofences = value => {
  if (!value || _isEmpty(value)) return '-';

  const geofenceNames = value?.map(geofence => geofence.name)?.join(', ');

  return geofenceNames;
};

export const getBatteryValueInVoltsAgGrid = ({ value }) => {
  const noBatteryValue = value === null || value === undefined || value === '';
  if (noBatteryValue) {
    return '-';
  }

  const valueInVolts = value / 1000;
  const formattedValueInVolts = _invoke(valueInVolts, 'toFixed', 2);

  return formattedValueInVolts;
};

export const formatLightsValueAgGrid = ({ value }) => {
  if (!value) {
    return '-';
  }

  return value;
};

export const formatAddressValueAgGrid = ({ value }) => {
  if (!value) {
    return '-';
  }

  return value.toString();
};

export const formatTISValueAgGrid = ({ value }) => {
  if (!value) {
    return '-';
  }

  return titleCase(value);
};

export const formatValueAgGrid = ({ value }) => {
  if (!value) {
    return '-';
  }

  return value;
};

export const formatPercentageAgGrid = ({ value }) => {
  if (!value) {
    return '-';
  }

  return `${value} %`;
};

export const formatVoltageAgGrid = ({ value }) => {
  if (!value) {
    return '-';
  }

  return `${value} V`;
};

export const formatHoursAgGrid = ({ value }) => {
  if (!value) {
    return '-';
  }

  return `${value} Hours`;
};

export const formatTempAgGrid = ({ value }) => {
  if (!value) {
    return '-';
  }

  return `${value.toFixed(0)} °F`;
};

export const formatSpeedValueAgGrid = ({ value }) => {
  if (!value) {
    return '-';
  }

  return Math.round(value) ?? '-';
};

export const formatSimStatusValueAgGrid = ({ value }) => {
  if (!value) {
    return '-';
  }

  return titleCase(value);
};

export const formatAgeAgGrid = ({ value }) => {
  if (!value && value !== 0) {
    return '-';
  }
  const formattedValue = value <= 0 ? 0 : value;

  return formattedValue === 1 ? `${formattedValue} year` : `${formattedValue} years`;
};

export const formatLengthAgGrid = ({ value }) => {
  if (!value) {
    return '-';
  }

  return `${value} feet`;
};

export const getFormattedDate = (value, dateFormat) => (value ? moment(value).tz(browsersTimezone).format(dateFormat) : '');

export const lastLoginFormatter = value => {
  if (!value?.value) {
    return 'Never Logged In';
  }
  return getFormattedTimestampAgGrid(value);
};

export const getColumnFieldName = (columns, colId) => {
  return Object.values(columns).find(column => column.apiResponseParsePath === colId).fieldName;
};
